import {
  FileExclamationOutlined,
  PoundOutlined,
  SearchOutlined,
  UserOutlined,
  UsergroupAddOutlined,
} from "@ant-design/icons";
import { Form, Input, Menu, Modal, Tag } from "antd";
import { Fragment, useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { OrganisationsContext } from "../../contexts";
import { NewOrganisation } from "../forms";
import { Dashboard } from "./Dashboard";

const { SubMenu } = Menu;

const SupervisorDashboard = () => {
  const [showModal, setShowModal] = useState(false);
  const handleClose = () => setShowModal(false);
  const handleShow = () => setShowModal(true);

  const [searchableOrganisations, setSearchableOrganisations] = useState();

  const { fetchOrganisations, organisations } =
    useContext(OrganisationsContext);

  useEffect(() => {
    if (!organisations) {
      fetchOrganisations();
    }
  }, [fetchOrganisations, organisations]);

  useEffect(() => {
    if (organisations) {
      setSearchableOrganisations(organisations);
    }
  }, [organisations]);

  return (
    <Dashboard
      homeLink={"/supervisorDashboard"}
      menuInterface={
        <Fragment>
          <Modal
            title="New Organisation"
            visible={showModal}
            footer={false}
            onCancel={handleClose}
            maskClosable={false}
          >
            <NewOrganisation refetch={fetchOrganisations} />
          </Modal>
          <Menu.Item key="2" icon={<FileExclamationOutlined />}>
            <Link
              to="/supervisorDashboard/pendingDeliverables"
              style={{ textDecoration: "none" }}
            >
              Pending Deliverables
            </Link>
          </Menu.Item>
          <SubMenu key="sub3" icon={<UserOutlined />} title="Organisations">
            <Menu.Item
              style={{ cursor: "pointer" }}
              onClick={() => {
                handleShow();
              }}
            >
              New Organisation
            </Menu.Item>
            <div className="client-list">
              <Form>
                <Form.Item className="client-list-form">
                  <Input
                    prefix={<SearchOutlined style={{ color: "lightgray" }} />}
                    placeholder="search organisation"
                    size="small"
                    className="search-input"
                    onChange={(e) => {
                      if (e.target.value === "") {
                        setSearchableOrganisations(organisations);
                      } else {
                        setSearchableOrganisations(
                          organisations.filter((el) =>
                            el.name
                              .toLowerCase()
                              .includes(e.target.value.toLowerCase())
                          )
                        );
                      }
                    }}
                  />
                </Form.Item>
              </Form>
              <div className="scrolllist-clients">
                {searchableOrganisations &&
                searchableOrganisations.length > 0 ? (
                  searchableOrganisations.map((organisation) => (
                    <Link
                      key={organisation.id}
                      to={`/supervisorDashboard/organisations/${organisation.id}/projects`}
                      id="project-names"
                    >
                      <span title={organisation.name}>{organisation.name}</span>
                    </Link>
                  ))
                ) : (
                  <p>no organisation was found</p>
                )}
              </div>
            </div>
          </SubMenu>
          <Menu.Item key="3" icon={<UsergroupAddOutlined />}>
            <Link
              to="/supervisorDashboard/accounts"
              style={{ textDecoration: "none" }}
            >
              Accounts
            </Link>
          </Menu.Item>
          <Menu.Item key="4" icon={<PoundOutlined />}>
            <Link
              to="/supervisorDashboard/subscriptions"
              style={{ textDecoration: "none" }}
            >
              Plans
            </Link>
          </Menu.Item>
          <Menu.Item key="6" icon={<UsergroupAddOutlined />} id="second-step">
            <Link
              to="/supervisorDashboard/metrics"
              style={{ textDecoration: "none" }}
            >
              AI{" "}
              <Tag
                color="#108ee9"
                style={{
                  marginLeft: 10,
                  padding: "0px 5px 0px",
                  borderRadius: 3,
                }}
              >
                New
              </Tag>
            </Link>
          </Menu.Item>
        </Fragment>
      }
    />
  );
};

export { SupervisorDashboard };

