import { Progress } from "antd";

const MerticLoading = ({ progressPercentage }) => {
  return (
    <div className="ant-row">
      <div className="metric-loader">
        <div>
          <Progress type="circle" percent={progressPercentage} />
        </div>
        <p>Processing Images...</p>
      </div>
    </div>
  );
};

export { MerticLoading };
