import { Form, Input, Menu, Skeleton, Tag } from "antd";
import { Dashboard } from "./Dashboard";
import {
  UserOutlined,
  SearchOutlined,
  UsergroupAddOutlined,
} from "@ant-design/icons";
import { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { OrganisationsContext } from "../../contexts";

const { SubMenu } = Menu;

const DataProcessorDashboard = () => {
  const [searchableOrganisations, setSearchableOrganisations] = useState();

  const { fetchOrganisations, organisations, isOrganisationsLoading } =
    useContext(OrganisationsContext);

  useEffect(() => {
    if (!organisations) {
      fetchOrganisations();
    }
  }, [fetchOrganisations, organisations]);

  useEffect(() => {
    if (organisations) {
      setSearchableOrganisations(organisations);
    }
  }, [organisations]);

  return (
    <Dashboard
      homeLink={"/dataprocessorDashboard"}
      menuInterface={
        <>
          <SubMenu key="sub3" icon={<UserOutlined />} title="Organisations">
            <div className="client-list">
              <Form>
                <Form.Item className="client-list-form">
                  <Input
                    prefix={<SearchOutlined style={{ color: "lightgray" }} />}
                    placeholder="search organisation"
                    size="small"
                    className="search-input"
                    onChange={(e) => {
                      if (e.target.value === "") {
                        setSearchableOrganisations(organisations);
                      } else {
                        setSearchableOrganisations(
                          organisations.filter((el) =>
                            el.name
                              .toLowerCase()
                              .includes(e.target.value.toLowerCase())
                          )
                        );
                      }
                    }}
                  />
                </Form.Item>
              </Form>
              <div className="scrolllist-clients">
                {isOrganisationsLoading && (
                  <Skeleton.Input
                    size={10}
                    active
                    block={true}
                    style={{ backgroundColor: "lightgray", borderRadius: 4 }}
                  />
                )}
                {searchableOrganisations &&
                searchableOrganisations.length > 0 ? (
                  searchableOrganisations.map((organisation) => (
                    <Link
                      key={organisation.id}
                      to={`/dataprocessorDashboard/organisations/${organisation.id}/projects`}
                      id="project-names"
                    >
                      <span title={organisation.name} className="test">
                        {String(organisation.name).toUpperCase()}
                      </span>
                    </Link>
                  ))
                ) : (
                  <p>No organisation was found</p>
                )}
              </div>
            </div>
          </SubMenu>

          <Menu.Item key="6" icon={<UsergroupAddOutlined />} id="second-step">
            <Link
              to="/dataprocessorDashboard/metrics"
              style={{ textDecoration: "none" }}
            >
              AI{" "}
              <Tag
                color="#108ee9"
                style={{
                  marginLeft: 10,
                  padding: "0px 5px 0px",
                  borderRadius: 3,
                }}
              >
                New
              </Tag>
            </Link>
          </Menu.Item>
        </>
      }
    />
  );
};

export { DataProcessorDashboard };
