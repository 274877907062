import { Outlet, useLocation } from 'react-router-dom';


const MetricsView = () => {
  const { pathname } = useLocation();
  return (
    <div className="subscription-container" style={{ padding: "20px" }}>
      <Outlet key={pathname} />
    </div>
  );
};

export { MetricsView };
